import React, { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import loginImage from '../assets/images/register.png';
import axios from 'axios';
import { useGlobalContext } from '../contexts/GlobalContext';
import Header from '../layout/Header';


const OtpVarification = () => {
  const { api_url, setIsLoading, setShowSchoolModal } = useGlobalContext();

  const navigate = useNavigate();
  const [passErrors, setPassErrors] = useState({});
  const location = useLocation();
  const [otpData, setOtpData] = useState({
    // otp: location.state?.otp,
    otp: '',
    phone_no: location.state?.phone_no
  });

  const handleChange = (e) => {
    setOtpData({ ...otpData, [e.target.name]: e.target.value })
  }

  const Validation = () => {
    let errors = {};

    if (!otpData.otp) {
      errors.otp = 'Please fill the required';
    }

    return errors;
  }

  //handleVerify
  const handleVerify = (e) => {
    e.preventDefault();

    let errors = Validation();
    setPassErrors(errors);

    if (Object.keys(errors).length === 0) {
      const prevPath = location.state.prevPath;
      let otp_type;
      (() => {
        switch (prevPath) {
          case '/signup':
            otp_type = "user_register";
            break;
          case '/cart':
            otp_type = 'checkout_register'
            break;
          case '/forgotpassword':
            otp_type = "forgot_password";
            break;
          default: otp_type = 'student_register'
        }
      })()
      console.log(prevPath, 'prev')
      const data = {
        'user_id': location.state.user_id,
        'otp': otpData.otp,
        'otp_type': otp_type
      }
      setIsLoading(true)
      axios.post(`${api_url}/testverifyOtp`, data)
        .then((res) => {
          if (res.data.success === 1) {
            setIsLoading(false)
            toast.success(`${res.data.message}`, {
              theme: 'colored',
              autoClose: 1500,
            })
            let timer = setTimeout(() => {
              sessionStorage.setItem('token', res.data.data.token);
              sessionStorage.setItem('username', res.data.data.name);
              sessionStorage.removeItem('guest_id')
              sessionStorage.setItem('user_id', res.data.data.user_id);
              res.data.data.school_code !== null && sessionStorage.setItem('school_code', res.data.data.school_code);

              if (prevPath === '/signup') {
                navigate('/');
              }
              else if (prevPath === '/forgotpassword') {
                navigate('/resetforgotpassword', { state: { user_id: location.state.user_id } });
              }
              else if (prevPath === '/cart') {
                navigate('/cart');
              }
              else {
                navigate('/myschool');
              }
            }, 2000);
            return () => clearTimeout(timer);
          }
          else {
            setIsLoading(false)
            toast.error(`${res.data.message} `, {
              theme: 'colored',
              autoClose: 1500,
            })
          }
        })
        .catch((err) => {
          setIsLoading(false)
          toast.error('Axios Error', {
            theme: 'colored',
            autoClose: 1500,
          })
          console.log(err)
        })
    }
  }

  const resendOtp = () => {
    toast.success('OTP Sent Successfully', {
      theme: 'colored',
      autoClose: 2000,
    })
  }

  return (
    <>
      <Header />
      <main id='main' className='mt-5 pt-5'>
        {/* {JSON.stringify(otpData)} */}
        <section id="" className='h-100'>
          <div className="container">
            <div className="row g-4 mt-md-5">

              <div className="col-md-6 pt-2">
                <img src={loginImage} alt='.' className="img-fluid d-none d-md-block" />

              </div>
              <div className="col-md-6">
                <div className="border border-1 border-dark rounded-2 p-2 shadow-md user">
                  <h3 className="text-center pt-3">Create Account</h3>
                  <form className="p-4">
                    <h5>An OTP has been sent to your mobile number</h5>
                    {otpData?.phone_no ? <h4>+91 ********{otpData?.phone_no.slice(8, 11)}</h4> : ''}

                    <div className="form-group pt-3">
                      <label htmlFor="number">Verify OTP</label>
                      <input type="number" className="form-control" id="number" placeholder="*******" onChange={handleChange} name='otp' value={otpData.otp} />
                      <div className="text-danger">{passErrors.otp}</div>
                    </div>
                    <button type="submit" className="mt-4 btn btn-danger w-100 " onClick={(e) => handleVerify(e)}>Confirm</button>
                    {
                      sessionStorage.getItem('school_code') === null ?

                        <button type='button' onClick={() => setShowSchoolModal(true)} className="my-3 btn border-dark shadow-btm w-100 text-danger fw-bold">My School</button>
                        :
                        <Link to={'/myschool'} className="my-3 btn border-dark shadow-btm w-100 text-danger fw-bold">
                          My School
                        </Link>
                    }
                    {/* <button className="my-3 btn border-dark shadow-btm w-100 text-danger fw-bold">My School</button> */}
                    <h6 className="mt-3 mb-1">Already have an account<span><Link to={'/login'} className="text-danger fw-bold"> Login</Link></span> here</h6>
                  </form>
                </div>
              </div>


              {/* <div className="col-md-3"></div>
              <div className="col-md-6">
                <div className="">
                  <div className="f-shadow bg-white">
                    <div className="mx-auto h6 m-0 p-3 bg-pink text-white">OTP Verification</div>
                    <form className="mx-auto border p-4">
                      <div className="form-floating mb-3">
                        <input type="text" className="form-control" onChange={handleChange} name='otp' value={otpData.otp} id="currentPassword" placeholder="Password" />
                        <label htmlFor="currentPassword">Enter OTP<span className="text-danger">*</span></label>
                        <div className="text-danger">{passErrors.otp}</div>
                      </div>
                      <div className="d-flex justify-content-end">

                        <div className=""><button onClick={(e) => handleVerify(e)} className="btn btn-pink d-block ms-auto">Verify</button></div>
                      </div>
                    </form>
                  </div>
                </div>
              </div> */}
              <div className="col-md-3"></div>
            </div>
          </div>
        </section>
      </main>


      <ToastContainer />
    </>
  )
}

export default OtpVarification