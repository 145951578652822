import React, { useEffect, useState } from 'react'
import { hdata } from '../data/Cdata'

import { Link } from 'react-router-dom'
import Header from '../layout/Header'
import Footer from '../layout/Footer'
import ProfileSidebar from '../components/ProfileSidebar'
import { useOrderContext } from '../contexts/OrderContext';
import OrderItem from '../components/OrderItem'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useGlobalContext } from '../contexts/GlobalContext'
import logo from '../assets/images/main-logo.svg';


const OrderHistory = () => {
  const { orders, getMyOrders } = useOrderContext();

  useEffect(() => {
    getMyOrders();
  }, []);
  return (
    <>
      <Header />
      <main id='main' className='mt-5 pt-5 space-top'>
        <div className="eVyapari-content w-100">
          <div className="text-center  mb-2 d-block d-md-none">
            <Link to={'/'} className="navbar-brand order-md-1 mx-auto" ><img src={logo} alt="e-vyapari-logo" /></Link>
          </div>
        </div>
        <section id="order-detail" className="pb-5 clearfix text-start">
          <div className="container">
            {/* <div className="my-5 d-flex justify-content-between my-acccount">
              <h4 className="text-start"><a href="my-account.html"><i className="ti ti-circle-arrow-left me-2" /></a>My Account</h4>
              <div className="btn-group bg-light">
                <a href="order-history.html" type="button" className="btn btn-danger active rounded-3 border border-1 border-dark px-5">Orders</a>
                <a href="order-history-cancelled.html" type="button" className="btn btn-white text-dark">Cancelled Orders</a>
              </div>
            </div> */}
            <ProfileSidebar />
            {/*--------------Order-history---------*/}
            {/* {JSON.stringify(orders)} */}
            <div className="float-md-end bill-details w-75 list-area text-sm-start text-start">
              {orders.length !== 0 ?
                orders.map((item, i) => {
                  const { invoice_number, order_total, grand_total, order_status, order_time, tracking_status } = item;
                  return (
                    <OrderItem
                      key={i}
                      srno={i + 1}
                      invoice_number={invoice_number}
                      grand_total={grand_total}
                      order_status={order_status}
                      order_date={order_time}
                      tracking_status={tracking_status}
                    />
                  )
                }) :
                <h6 className='text-center text-danger'>No Order Found</h6>
              }
              {/* {orders.length > 0 && orders.map((item, i) => {
                const isDelivered = item.tracking_status.includes('Delivered');

                return (
                  <div className="card border-0 shadow rounded-4 pt-4 px-2 mb-4">
                    <div className="row">
                      <div className="col-12">
                        <h6 className="ps-3 d-flex align-items-center text-center ord-opt"><i className="ti ti-package text-light bg-dark fs-2 rounded-2 me-2" /> <span className={isDelivered ? "text-dark fw-bold" : "text-warning fw-bold"} dangerouslySetInnerHTML={{ __html: item.tracking_status }} />
                        </h6>

                      </div>
                      <div className="col-12">
                        <h6 className="ps-3 short">On Sat, 22 Apr 2023</h6>
                      </div>
                    </div>
                    <div className="card-body d-flex align-items-center flex-wrap text-start">
                      <img className="rounded-4 mx-sm-0 mx-auto" src="assets/images/order-history.png" alt="cart-img" />
                      <div className="mx-3 mt-1">
                        <h5 className="mb-0 ord-tit">JMD Computer Science</h5>
                        <h6 className="short">Short Cut To Sure Success</h6>
                        <ul className="list-inline fs-4 mb-0">
                          <li className="list-inline-item me-0"><i className="ti ti-star" /></li>
                          <li className="list-inline-item me-0"><i className="ti ti-star" /></li>
                          <li className="list-inline-item me-0"><i className="ti ti-star" /></li>
                          <li className="list-inline-item me-0"><i className="ti ti-star" /></li>
                          <li className="list-inline-item me-0"><i className="ti ti-star" /></li>
                        </ul>
                        <h6 className="text-danger short">Write a Review</h6>
                      </div>
                      <div className="ms-auto pe-3 pt-5">
                        <h6 className="d-flex align-items-center text-center mb-0 ord-opt"><i className="ti ti-credit-card-refund text-end text-light bg-danger rounded-3 p-1 me-3 fs-4" />Re-Order</h6>
                      </div>
                    </div>
                  </div>
            
                );
              })} */}


            </div>
          </div>
        </section>


      </main>
      <Footer />
    </>
  )
}

export default OrderHistory