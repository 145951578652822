import React from 'react'

const Loading = () => {
    return (
        <>

            <div
                style={{ height: "100vh" }}
                className="d-flex position-fixed top-0 bottom-0 start-0 loading end-0 align-items-center justify-content-center"
            >
                <div className="spinner-border text-danger" role="status"></div>
            </div>
        </>
    )
}

export default Loading