import React, { useEffect, useState } from 'react'
import arrow_circle_left from '../assets/images/arrow_circle_left.png'
import bnr1 from '../assets/images/bnr1.jpg'
import bnr2 from '../assets/images/bnr2.jpg'
import bnr3 from '../assets/images/bnr3.jpg'
import bnr4 from '../assets/images/bnr4.jpg'
import browse from '../assets/images/browse.png'
import order from '../assets/images/order.png'
import delivery from '../assets/images/delivery.png'
import orderBook from '../assets/images/order-book.png'
import bookimg from '../assets/images/book.png'
import classroom from '../assets/images/classroom.png'
import CrousalCard from './CrousalCard'
import notfound from '../assets/images/imagenofound.png'
import logo from '../assets/images/main-logo.svg';

import { useInventoryContext } from '../contexts/InventoryContext'
import { useGlobalContext } from '../contexts/GlobalContext'
import ProductCard from './ProductCard'
import CarouselSlider from './CarouselSlider/CarouselSlider'
import CarouselSlider2 from './CarouselSlider/CarouselSlider copy'
import SchoolCard from './SchoolCard'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Loading from './loading/Loading'
import ViewRating from './ViewRating'

const Banner = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { getHomeInventory, homeInventory, homeInventoryBook, homeInventoryBags, homeInventorystationary, homeInventoryNew, getHomeInventoryNew } = useInventoryContext()
    const { api_url, bestSellerData, img_url, schools, getBestSellerData, getSchools, convertToPlain, setShowSchoolModal, getAllInventory, allInventory, getCategories, categoryData, bestSellerImageData, getCartItems, handleAddToWishlist, setWishlistLength, isLoading } = useGlobalContext();
    const { getPageInventory, searchKeyword, setSearchKeyword, getLastCategories, handleAddToCart, setPageInventory, setHomeInventory, setAllInventory } = useInventoryContext();
    useEffect(() => {
        getBestSellerData()
        getSchools()
        getHomeInventoryNew()
        getHomeInventory()
    }, [])
    const handlewish = (product_id) => {

        handleAddToWishlist(product_id)

        // setWishlistLength((prev) => prev + 1);

    }

    //Get All Categories
    const url = `${api_url}/pageInventory`;

    useEffect(() => {
        // getCategories();
        // getCartItems();

    }, []);


    useEffect(() => {
        setSearchKeyword('')
    }, [location]);



    // const url = `${api_url}/pageInventory`;

    const handleSearch = () => {
        // getPageInventory(url);
        if (location.pathname !== '/marketplace') {
            // navigate('/marketplace')
            navigate({
                pathname: '/marketplace',
                search: `?search=${searchKeyword}`,
            });
        }
        // navigate('/marketplace')
        // navigate('/marketplace', { state: {'searchKeyword' : searchKeyword} });
    }

    const handleCategory = (cat_id, subcat_id, subcat2_id) => {
        getPageInventory(url, null, cat_id, subcat_id, subcat2_id);
        getLastCategories(cat_id, subcat_id, subcat2_id)
        navigate('/marketplace', { state: { cat_id: cat_id, subcat_id: subcat_id, subcat2_id: subcat2_id } })
    }

    // when sign out
    // const handleLogout = () => {
    //     sessionStorage.removeItem('token');
    //     sessionStorage.removeItem('user_id');
    //     sessionStorage.removeItem('school_code');
    //     navigate('/');
    //     window.location.reload();
    // }
    const [productRating, setProductRating] = useState(0);

    //getProductRating
    const getProductRating = () => {
        let prating = 0;
        bestSellerData?.reviews.forEach((ele, i) => {
            prating += ele.rating;
        })
        prating = (prating / bestSellerData?.reviews.length).toFixed(1);
        setProductRating(prating)
    }
    useEffect(() => {
        if (bestSellerData?.reviews?.length > 0) {
            getProductRating();
            console.log(bestSellerData?.reviews)
        }
    }, [bestSellerData?.reviews])
    const productCarouselItems = homeInventory.map((card, i) => {

        const { id, reviews, product_id, image, folder, product_name, mrp, type, description, discounted_price, shipping_charges, vendor_id, itemExistInCart, itemExistInWishlist } = card;
        return (

            <ProductCard
                key={i}
                id={id}
                product_id={product_id}
                image={`${img_url}/${folder}/${image}` || `${notfound}`}
                title={product_name}
                mrp={mrp} reviews={reviews}
                discounted_price={discounted_price}
                shipping_charges={shipping_charges}
                type={type}
                text={(description)}
                Card={card}
                itemExistInCart={itemExistInCart}
                itemExistInWishlist={itemExistInWishlist}
            />
        )
    })
    const productBookItems = homeInventoryNew[2]?.products?.map((card, i) => {

        const { id,
            reviews, product_id, image, folder, product_name, mrp, type, description, discounted_price, shipping_charges, vendor_id, itemExistInCart, itemExistInWishlist } = card;
        return (

            <ProductCard
                key={i}
                id={id}
                product_id={product_id}
                image={`${img_url}/${folder}/${image}` || `${notfound}`}
                title={product_name}
                mrp={mrp}
                reviews={reviews}
                discounted_price={discounted_price}
                shipping_charges={shipping_charges}
                type={type}
                text={(description)}
                Card={card}
                itemExistInCart={itemExistInCart}
                itemExistInWishlist={itemExistInWishlist}
            />
        )
    })
    const productBagsItems = homeInventoryNew[0]?.products?.map((card, i) => {

        const { id, reviews, product_id, image, folder, product_name, mrp, type, description, discounted_price, shipping_charges, vendor_id, itemExistInCart, itemExistInWishlist } = card;
        return (

            <ProductCard
                key={i}
                id={id}
                product_id={product_id}
                image={`${img_url}/${folder}/${image}` || `${notfound}`}
                title={product_name}
                mrp={mrp}
                reviews={reviews}
                discounted_price={discounted_price}
                shipping_charges={shipping_charges}
                type={type}
                text={(description)}
                Card={card}
                itemExistInCart={itemExistInCart}
                itemExistInWishlist={itemExistInWishlist}
            />
        )
    })
    const productStationaryItems = homeInventoryNew[1]?.products?.map((card, i) => {

        const { id, reviews, product_id, image, folder, product_name, mrp, type, description, discounted_price, shipping_charges, vendor_id, itemExistInCart, itemExistInWishlist } = card;
        return (

            <ProductCard
                key={i}
                id={id}
                product_id={product_id}
                image={`${img_url}/${folder}/${image}` || `${notfound}`}
                title={product_name}
                mrp={mrp}
                reviews={reviews}
                discounted_price={discounted_price}
                shipping_charges={shipping_charges}
                type={type}
                text={(description)}
                Card={card}
                itemExistInCart={itemExistInCart}
                itemExistInWishlist={itemExistInWishlist}
            />
        )
    })
    const productStationaryItems2 = homeInventoryNew[1]?.products?.slice().reverse().map((card, i) => {

        const { id, reviews, product_id, image, folder, product_name, mrp, type, description, discounted_price, shipping_charges, vendor_id, itemExistInCart, itemExistInWishlist } = card;
        return (
            <div className="col-lg-3 col-sm-4 col-6 ">
                <ProductCard
                    key={i}
                    id={id}
                    product_id={product_id}
                    image={`${img_url}/${folder}/${image}` || `${notfound}`}
                    title={product_name}
                    mrp={mrp}
                    reviews={reviews}
                    discounted_price={discounted_price}
                    shipping_charges={shipping_charges}
                    type={type}
                    text={(description)}
                    Card={card}
                    itemExistInCart={itemExistInCart}
                    itemExistInWishlist={itemExistInWishlist}
                />
            </div>
        )
    })
    const productBagsItems2 = homeInventoryNew[0]?.products?.map((card, i) => {
        const { id, reviews, product_id, image, folder, product_name, mrp, type, description, discounted_price, shipping_charges, vendor_id, itemExistInCart, itemExistInWishlist } = card;
        return (

            <div className="col-lg-3 col-sm-4 col-6 ">
                <ProductCard
                    key={i}
                    id={id}
                    reviews={reviews}
                    product_id={product_id}
                    image={`${img_url}/${folder}/${image}` || `${notfound}`}
                    title={product_name}
                    mrp={mrp}
                    discounted_price={discounted_price}
                    shipping_charges={shipping_charges}
                    type={type}
                    text={(description)}
                    Card={card}
                    itemExistInCart={itemExistInCart}
                    itemExistInWishlist={itemExistInWishlist}
                />
            </div>
        )
    })

    const schoolCarouselItems = schools.map((school, i) => {
        const { school_name, school_banner, folder, school_code } = school;
        // console.log(school,'dsd')
        return (
            <SchoolCard
                key={i}
                school_code={school_code}
                school_name={school_name}
                school_image={`${img_url}/${folder}/${school_banner}`}
                school_banner={school_banner}
            />
        )
    })
    const text = bestSellerData?.description
    return (
        <>
            {/* {console.log(mostOrderBook)} */}
            {isLoading || !productBagsItems ? <Loading /> : null}
            {/* {console.log(homeInventoryNew)}
            {console.log(homeInventoryBook)}
            {console.log(homeInventoryBags, homeInventorystationary,)} */}

            <main>
                <section id="search-area" className="py-5">
                    <div className="container h-100">
                        <div className="row g-4 align-items-center h-100">
                            <div className="col-md-6 h-100 eVyapari-content ">
                                <div className="text-center  mb-4 d-block d-md-none">
                                    <Link to={'/'} className="navbar-brand order-md-1 mx-auto" ><img src={logo} alt="e-vyapari-logo" /></Link>
                                </div>

                                <h1 className='d-none d-md-block'>e-Vyapari</h1>
                                <h6 className='d-none d-md-block'>Find the best children books for your school, delivered right to your doorstep.</h6>
                                <div className="input-group mb-3 shadow-md rounded-3" style={{ height: '53px' }}>
                                    <input type="text" className="form-control  border-end-0" onChange={(e) => setSearchKeyword(e.target.value)} onKeyDown={(e) => { if (e.key === "Enter") handleSearch() }} value={searchKeyword !== null ? searchKeyword : ''} placeholder="Search Items" />
                                    <div className="input-group-append">
                                        <span className="input-group-text search-icon  h-100 cursor-pointer" onClick={() => handleSearch()}>
                                            <i className="fa-solid fa-magnifying-glass p-2 bg-dark text-light rounded-3" />
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 h-100">
                                <div className="row g-4">
                                    <div className="col-md-6 d-none d-md-block">
                                        <div className="card rounded-4 overflow-hidden shadow-lg border-0 mb-4">
                                            <div className="card-body p-0 position-relative">
                                                <img src={bnr1} alt='image-banner' className="zoom object-img" />
                                                <Link to={'/popularschool'} className=" text-decoration-none position-absolute bottom-0 start-50 translate-middle-x d-flex align-items-end mb-3">
                                                    <span className="text-white me-3">
                                                        <h5 className="mb-2 fw-semibold">Popular</h5>
                                                        <h2 className="mb-0 fw-bold">Schools</h2>
                                                    </span>
                                                    <img src={arrow_circle_left} alt='image-banner' className="arrow-img d-block" />
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="card rounded-4 overflow-hidden shadow-lg border-0">
                                            <div className="card-body p-0 position-relative">
                                                <img src={bnr2} alt='image-banner' className="zoom object-img" />
                                                <Link to={'https://www.youtube.com/@evyaparii'} target='_blank' className="order text-decoration-none position-absolute bottom-0 start-50 translate-middle-x d-flex align-items-end mb-3">
                                                    <span className="text-white me-3">
                                                        <h5 className="mb-0 fw-semibold">How to</h5>
                                                        <h2 className="mb-0 fw-bold">Order</h2>
                                                    </span>
                                                    <img src={arrow_circle_left} alt='image-banner' className="arrow-img d-block" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 d-none d-md-block">
                                        <div className="card rounded-4 overflow-hidden  myschool border-0 mb-4">
                                            <div className="card-body p-0 position-relative sch-bnr-height">
                                                <img src={bnr3} alt='image-banner' className="zoom object-img" />
                                                {
                                                    sessionStorage.getItem('school_code') === null ?
                                                        <button onClick={() => setShowSchoolModal(true)} className="text-start bg-transparent border-0 text-decoration-none position-absolute bottom-0 start-50 translate-middle-x d-flex align-items-end mb-3">
                                                            <span className="text-white me-3">
                                                                <h5 className="mb-0 fw-semibold">My School</h5>
                                                                <h2 className="mb-0 fw-bold">Search</h2>
                                                            </span>
                                                            <img src={arrow_circle_left} alt='image-banner' className="arrow-img d-block" />
                                                        </button>
                                                        :
                                                        <Link to={'/myschool'} className=" text-decoration-none position-absolute bottom-0 start-50 translate-middle-x d-flex align-items-end mb-3">
                                                            <span className="text-white me-3">
                                                                <h5 className="mb-0 fw-semibold">My School</h5>
                                                                <h2 className="mb-0 fw-bold">Search</h2>
                                                            </span>
                                                            <img src={arrow_circle_left} alt='image-banner' className="arrow-img d-block" />
                                                        </Link>
                                                }
                                                {/* <Link to={'/myschool'} className=" text-decoration-none position-absolute bottom-0 start-50 translate-middle-x d-flex align-items-end mb-3">
                                                    <span className="text-white me-3">
                                                        <h5 className="mb-2">My School</h5>
                                                        <h2 className="mb-0">Search</h2>
                                                    </span>
                                                    <img src={arrow_circle_left} alt='image-banner' className="d-block" />
                                                </Link> */}
                                            </div>
                                        </div>
                                        <div className="card rounded-4 overflow-hidden shadow-lg border-0">
                                            <div className="card-body p-0 position-relative">
                                                <img src={bnr4} alt='image-banner' className="zoom object-img" />
                                                <a href='#' className="text-decoration-none place position-absolute bottom-0 start-50 translate-middle-x d-flex align-items-end mb-3">
                                                    <span className="text-white me-3">
                                                        <h5 className="mb-0 fw-semibold">Hamirpur</h5>
                                                        <h2 className="mb-0 fw-bold">Himachal</h2>
                                                    </span>
                                                    <img src={arrow_circle_left} alt='image-banner' className="arrow-img d-block" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-md-6 d-block d-md-none">
                                <div className="row g-2 h-100">
                                    <div className="col-6 h-100">
                                        <Link to={'https://www.youtube.com/@evyaparii'} target='_blank' className="w-100 h-100 btn  text-dark rounded-2 border-dark shadow-md fw-bold">
                                            How to Order?
                                        </Link>
                                    </div>
                                    <div className="col-6 h-100">{
                                        sessionStorage.getItem('school_code') === null ?
                                            <Link onClick={() => setShowSchoolModal(true)} className="w-100 h-100 btn bg-danger text-white rounded-2 border-dark shadow-md fw-bold">
                                                <span>My School Search</span>
                                            </Link>
                                            :
                                            <Link to={'/myschool'} className="w-100 h-100 btn bg-danger text-white rounded-2 border-dark shadow-md fw-bold">
                                                <span>My School Search</span>

                                            </Link>
                                    }</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="steps" className="bg-danger text-center py-5 d-none d-md-block">
                    <div className="container">
                        <h4 className="text-light mb-5">3 easy steps to make order</h4>
                        <div className="row g-4 justify-content-center">
                            <div className="col-md-3">
                                <div className="card rounded-4 overflow-hidden border-0">
                                    <div className="card-body p-0 position-relative">
                                        <img src={browse} alt='image-banner' className="object-img" />
                                        <h6 className="position-absolute bottom-0 start-50 translate-middle-x mb-3 text-white"><span className="me-2">1. </span> Browse</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="card rounded-4 overflow-hidden border-0">
                                    <div className="card-body p-0 position-relative">
                                        <img src={order} alt='image-banner' className="object-img" />
                                        <h6 className="position-absolute bottom-0 start-50 translate-middle-x mb-3 text-white"><span className="me-2">2. </span>Place Order</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="card rounded-4 overflow-hidden border-0">
                                    <div className="card-body p-0 position-relative">
                                        <img src={delivery} alt='image-banner' className="object-img" />
                                        <h6 className="position-absolute bottom-0 start-50 translate-middle-x mb-3 text-white"><span className="me-2">3.</span>Doorstep Delivery</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <CarouselSlider items={productBookItems} title='New Arrival in Books' auto_play={true} display_dots={false} />
                {/* {JSON.stringify(bestSellerImageData)} */}
                {/* {JSON.stringify(bestSellerData)} */}
                {/* {console.log(bestSellerData)} */}
                <section id="best-seller" className="py-sm-5  pb-4 ">
                    <div className="container">
                        <h4 className="mb-5">Best Seller of {new Date().toLocaleString('default', { month: 'long' })}</h4>
                        {bestSellerData && <div className="card rounded-5 shadow px-3 py-0 mb-md-5 mb-2 bg-body rounded  border-0">
                            <div className="card-body">
                                <div className='d-flex   gap-4'>
                                    <div className='best-seller-card-container w-25'>
                                        {bestSellerImageData.map((data, i) => {
                                            const { image, alt, folder, dp_status } = data
                                            if (dp_status === 1) {
                                                return (
                                                    <img src={`${img_url}/${data?.folder}/${data?.image}`} key={i} className="img-fluid rounded-start" alt="..." />
                                                )
                                            }
                                        })}
                                    </div>
                                    {/* {bestSellerImageData?.image} */}
                                    <div className="card-body w-75 align-items-center justify-content-start d-flex">
                                        <div className='row  w-100 ' >
                                            <div className="col-md-8 col-12 position-relative">
                                                <Link to={`product/${bestSellerData?.id}`} onClick={() => window.scrollTo({ top: 0, behavior: 'instant' })} className='text-decoration-none text-dark'>
                                                    <h6 className="card-title fw-bold text-start">{bestSellerData?.product_name?.slice(0, 30)}{bestSellerData?.product_name > bestSellerData?.product_name?.slice(0, 33) ? "..." : null}</h6>
                                                </Link>
                                                <p className="card-text mb-1 text-start">
                                                    <span className='d-flex align-items-start justify-content-start text-start ms-0' dangerouslySetInnerHTML={{
                                                        __html: text?.replace(/<[^>]+>/g, '').length > 30
                                                            ? text?.replace(/<[^>]+>/g, '').slice(0, 30) + '...'
                                                            : text?.replace(/<[^>]+>/g, '')
                                                    }} />
                                                    {/* {bestSellerData?.description?.slice(0, 30)}{bestSellerData?.description > bestSellerData?.description?.slice(0, 33) ? "..." : null} */}
                                                </p>
                                                {/* <ul className="list-inline text-start">
                                                    <li className="list-inline-item"><i className="fa-solid fa-star text-warning" /></li>
                                                    <li className="list-inline-item"><i className="fa-solid fa-star text-warning" /></li>
                                                    <li className="list-inline-item"><i className="fa-solid fa-star text-warning" /></li>
                                                    <li className="list-inline-item"><i className="fa-solid fa-star text-warning" /></li>
                                                    <li className="list-inline-item"><i className="fa-regular fa-star text-warning" /></li>
                                                </ul> */}
                                                <span className='text-start'>

                                                   
                                                       
                                                        <ViewRating stars={productRating} />
                                               
                                                </span>
                                                <p className="card-text text-start">As Per New Revised Syllabus</p>
                                                <div className="vr d-md-inline-block d-none h-100 top-0  end-0 position-absolute"></div>
                                            </div>




                                            <div className="col-md-4 col-12 text-start">
                                                <div className='d-flex  align-items-center justify-content-start '>
                                                    <div className="w-100 ">
                                                        <div className="price text-start"><span className="fs-4  fw-bold me-2 text-secondary">₹&nbsp;<del className='fs-4 '>{bestSellerData?.mrp !== bestSellerData?.discounted_price ? bestSellerData?.mrp : ''}</del> <span className='fs-3 text-dark'>
                                                            ₹&nbsp;{bestSellerData?.discounted_price}
                                                        </span>
                                                        </span> </div>
                                                        <div className="row cartrow g-0">
                                                            <div className="col-md-12 col-sm-6 col-5 order-md-1 order-2">

                                                                <div className=" mt-3 w-100">
                                                                    <button className="btn btn-dark w-100 rounded-3" onClick={(e) => { handleAddToCart(bestSellerData?.id); }}> <i className="fa-solid fa-cart-shopping text-light me-1" /><span className='d-sm-inline d-none'>  Add To    </span> Cart</button>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12 col-sm-6 col-7 d-flex align-items-center order-md-2 order-1">
                                                                <div className=" mt-4 fw-bold btn" onClick={() => handlewish(bestSellerData?.id)}>
                                                                    <i className="fa-regular fa-heart text-danger" /><span className='d-sm-inline d-none'>  Add To    </span> Wishlist
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                </div>
                                {/* <div className="row g-3">
                                    <div className="col-md-8">
                                        <div className="card mb-3 border-0">
                                            <div className="row g-0">
                                                <div className="col-md-3">
                                                    <div className='best-seller-card-container'>
                                                        <img src={bookimg} className="img-fluid rounded-start" alt="..." />
                                                    </div>
                                                </div>
                                                <div className="col-md-9">
                                                    <div className="card-body">
                                                        <h6 className="card-title fw-bold mt-5">JMD Economics</h6>
                                                        <p className="card-text mb-1">Short Cut To Sure Success By Dinesh</p>
                                                        <ul className="list-inline">
                                                            <li className="list-inline-item"><i className="fa-solid fa-star text-warning" /></li>
                                                            <li className="list-inline-item"><i className="fa-solid fa-star text-warning" /></li>
                                                            <li className="list-inline-item"><i className="fa-solid fa-star text-warning" /></li>
                                                            <li className="list-inline-item"><i className="fa-solid fa-star text-warning" /></li>
                                                            <li className="list-inline-item"><i className="fa-regular fa-star text-warning" /></li>
                                                        </ul>
                                                        <p className="card-text">As Per New Revised Syllabus For 2025 Himachal Board Exams</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 ">
                                        <div className='d-flex flex-column align-items-start justify-content-center h-100 w-100'>

                                            <div className="price"><span className=" text-dark fw-bold me-2">₹
                                                297</span> </div>
                                            <div className=" mt-3 w-100">
                                                <a href="#" className="btn btn-dark w-100 px-4 rounded-3"> <i className="fa-solid fa-cart-shopping text-light me-3" /> Add To Cart</a>
                                            </div>
                                            <div className=" mt-4 ">
                                                <i className="fa-regular fa-heart text-danger" /> Add To Wishlist
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>}
                        {!bestSellerData && <h4 className='text-danger'>Available soon.........</h4>}
                    </div>
                </section>
                {/* {console.log(productBagsItems?.length)} */}
                {/* <CarouselSlider items={productBookItems} title='Most Ordered Books' auto_play={true} display_dots={false} /> */}
                {productBagsItems?.length < 4 ? <div className="container">
                    <h4 className="mb-5">New Arrival  in School Bags</h4><div className='row g-3'>{productBagsItems2}</div> </div> : <CarouselSlider items={productBagsItems} title='New Arrival  in School Bags' auto_play={true} display_dots={false} />}

                {productStationaryItems?.length < 4 ? <div className="container mb-5">
                    <h4 className="mb-5">New Arrival  in Stationery</h4>
                    {/* <h4>New Arival in Stationary</h4> */}
                    <div className='row g-3'>{productStationaryItems2}</div>
                </div>
                    : <CarouselSlider items={productStationaryItems} title='New Arrival  in Stationery' auto_play={true} display_dots={false} />}
                {/* <CarouselSlider items={productBagsItems} title='School Bags' auto_play={true} display_dots={false} /> */}
                {/* <CrousalCard title='New Arrival' mostOrderBook={homeInventory} /> */}
                {/* {console.log(productCarouselItems)} */}
                {/* <section id="new-arrival" className="py-5 bg-danger">
                    <div className="container">
                        <h4 className="mb-5 text-center text-light">e-Vyapari Popular Schools</h4>
                        <div className="row g-4">
                            <div className="col-md-3">
                                <div className="card border-dark shadow-btm rounded-4 h-100">
                                    <div className="card-body">
                                        <img src={classroom} alt='image-banner' className="d-block img-fluid mx-auto rounded-4" />
                                        <h5 className="text-center fw-bold mt-2">S.V.M Dhami</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="card border-dark shadow-btm rounded-4 h-100">
                                    <div className="card-body">
                                        <img src={classroom} alt='image-banner' className="d-block img-fluid mx-auto rounded-4" />
                                        <h5 className="text-center fw-bold mt-2">S.V.M Dhami</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="card border-dark shadow-btm rounded-4 h-100">
                                    <div className="card-body">
                                        <img src={classroom} alt='image-banner' className="d-block img-fluid mx-auto rounded-4" />
                                        <h5 className="text-center fw-bold mt-2">S.V.M Dhami</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="card border-dark shadow-btm rounded-4 h-100">
                                    <div className="card-body">
                                        <img src={classroom} alt='image-banner' className="d-block img-fluid mx-auto rounded-4" />
                                        <h5 className="text-center fw-bold mt-2">S.V.M Dhami</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
                <section id="new-arrival" className="py-5 bg-danger">
                    <div className="container">

                        <CarouselSlider2 items={schoolCarouselItems} title='e-Vyapari Popular Schools' auto_play={true} />
                    </div>
                </section>
            </main>
        </>
    )
}

export default Banner