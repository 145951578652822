import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LoginValidation } from './FormValidation';
import axios from 'axios';
import { useGlobalContext } from '../contexts/GlobalContext';


// import Copyright from '../Components/Copyright';
import ReCAPTCHA from 'react-google-recaptcha';

import loginImage from '../assets/images/login.png';
import schoolimg from '../assets/images/svm-rashmi.png';
import { Button, Form, Modal } from 'react-bootstrap';
// import TermsConditions from './TermsConditions';
import logo from '../assets/images/main-logo.svg';
import Loading from '../components/loading/Loading';
import Header from '../layout/Header';
const DelteuserAccout = () => {

    const { isLoading, setIsLoading, api_url, userId, setShowSchoolModal } = useGlobalContext();
    const navigate = useNavigate();
    const location = useLocation();
    const [loginErrors, setLoginErrors] = useState({});
    const [loginData, setLoginData] = useState({
        "user_id": userId,
        "email": location.state?.email_phone,
        "password": ""
    });
    const [passShow, setPassShow] = useState(true);
    const [type, setType] = useState('password')
    // const recaptcha = useRef();

    const togglePassword = () => {
        if (type === 'password') {
            setType('text')
        } else {
            setType('password')
        }
        setPassShow(!passShow)
    };

    const [showOtp, setShowOtp] = useState(false);
    var phoneRegex = /^\d{10}$/;
    var emailPhoneRegex = /^(?:\d{10}|\w+.+@\w+\.\w{2,3})$/
    var passwordRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");

    const changeHandler = (e) => {
        setLoginData({ ...loginData, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        if (emailPhoneRegex.test(loginData.email)) {
            setLoginErrors({ ...loginErrors, email: '' })
        }
    }, [loginData.email]);

    useEffect(() => {
        if (passwordRegex.test(loginData.password) && loginData.password.length >= 8) {
            setLoginErrors({ ...loginErrors, password: '' })
        }
    }, [loginData.password]);

    useEffect(() => {
        if (phoneRegex.test(loginData.email)) {
            setShowOtp(true)
        } else {
            setShowOtp(false)
        }
    }, [loginData.email])


    //handleOtpLogin
    const handleOtpLogin = async (e) => {
        e.preventDefault();

        const data = {
            "user_id": userId,
            "phone_no": loginData.email,
            "otp_type": "login"
        }
        setIsLoading(true)
        try {
            let res = await axios.post(`${api_url}/login`, data);
            if (res.data.success === 1) {
                let loginData = res.data.data;
                toast.success(res.data.message, {
                    theme: "colored",
                    autoClose: 1500,
                });
                const timer = setTimeout(() => {
                    navigate('/otplogin', { state: { user_id: res.data.data.user_id, phone: data.phone_no, otp: res.data.data.otp, prevPath: window.location.pathname } });
                }, 2000);
                return () => clearTimeout(timer);
            } else {
                toast.error(res.data.message, {
                    autoClose: 2000,
                });
            }
        } catch (error) {
            toast.error("Something Wrong happened", {
                autoClose: 2000
            });
        } finally {
            setIsLoading(false)
        }
    }

    //Login
    const handleDelete = (e) => {
        e.preventDefault();
        // const captchaValue = recaptcha.current.getValue()
        let errors = LoginValidation(loginData);
        setLoginErrors(errors);

        if (Object.keys(errors).length === 0) {
            // if (captchaValue) {
            //     alert('Please verify the reCAPTCHA!')
            // }
            // else {
            setIsLoading(true);
            axios.post(`${api_url}/deleteuser`, loginData)
                .then(res => {
                    if (res.data.success === 1) {
                        let loginData = res.data.data;
                        // console.log('cfnsjdncskd', res.data)

                        setIsLoading(false);
                        toast.success(res.data.message, {
                            theme: "colored",
                            autoClose: 2000,
                        });
                        const timer = setTimeout(() => {
                            sessionStorage.removeItem('token');
                            sessionStorage.removeItem('user_id');
                            sessionStorage.removeItem('school_code');
                            sessionStorage.removeItem('username')
                            navigate('/');
                            window.location.reload();
                        }, 3000);
                        return () => clearTimeout(timer);

                    } else {
                        setIsLoading(false)
                        toast.error(res.data.message, {
                            autoClose: 2000,
                        });
                    }
                })
                .catch(err => {
                    console.log(err)
                    toast.error("axios Error", {
                        autoClose: 2000
                    });
                    setIsLoading(false);
                })
            // }
        }
    }
    const [setsearchSchool, setSetsearchSchool] = useState(false)
    const handleClose = () => setSetsearchSchool(false)
    const show = () => setSetsearchSchool(true)
    const [setsearchSchool2, setSetsearchSchool2] = useState(false)
    const handleClose2 = () => setSetsearchSchool2(false)
    const show2 = () => setSetsearchSchool2(true)
    const [setsearchSchool3, setSetsearchSchool3] = useState(false)
    const handleClose3 = () => setSetsearchSchool3(false)
    const show3 = () => setSetsearchSchool3(true)
    return (
        <>
            <Header />
            {isLoading ? <Loading /> : null}

            <main id='main' className='mt-5 pt-5 space-top'>
                <div className="eVyapari-content w-100">
                    <div className="text-center  mb-2 d-block d-md-none">
                        <Link to={'/'} className="navbar-brand order-md-1 mx-auto" ><img src={logo} alt="e-vyapari-logo" /></Link>
                    </div>
                </div>
                <section id="login" className='h-100 mt-md-5 mt-2 mb-5'>
                    <div className="container">
                        <div className="d-flex align-items-center justify-content-center">
                            <div className="border bg-white border-1 border-dark rounded-2 p-2 shadow-md user">
                                <h3 className="text-center pt-3">Delete Account</h3>
                                <form className="p-4" onSubmit={handleDelete}>
                                    <div className="form-group">
                                        <label htmlFor="email">Email/Mobile No.<span className="text-danger">*</span></label>
                                        <div className="row">

                                            <div className={`col-12`}>
                                                <input type="text" name="email" onChange={changeHandler} value={loginData.email} className="form-control" id="email" placeholder="Email/Mobile No." />
                                            </div>

                                        </div>

                                    </div>
                                    <div className="text-danger">{loginErrors.email}</div>

                                    <div className="form-group pt-3">
                                        <label htmlFor="pwd">Password<span className="text-danger">*</span></label>
                                        <div className="input-group">
                                            <input type={type} id="password" placeholder="********" className="form-control" name="password" onChange={changeHandler} value={loginData.password} />

                                            <span className="input-group-text bg-white cursor-pointer" onClick={togglePassword}> {passShow ? <i className="fa-regular fa-eye"></i> : <i className="fa-regular fa-eye-slash"></i>} </span>
                                        </div>
                                    </div>
                                    <div className="text-danger">{loginErrors.password}</div>

                                    <button type="submit" className="mt-4 btn btn-danger d-block w-100"><i className='ti ti-trash text-white' /> Delete </button>
                                    <button type="button" onClick={() => navigate(-1)} className="mt-4 btn border-2 border-danger text-danger d-block w-100"><i className='ti ti-circle-arrow-left text-danger' /> Back </button>


                                </form>
                            </div>
                        </div>


                    </div>
                </section>
            </main>
        </>
    )
}

export default DelteuserAccout