import React, { useEffect, useState } from 'react'
import Header from '../layout/Header'
import { useGlobalContext } from '../contexts/GlobalContext';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Link, useParams } from 'react-router-dom';
import logo from '../assets/images/main-logo.svg';

const PaymentStatus = () => {
    const { setIsLoading, userId, api_url } = useGlobalContext();
    const [paymentStatus, setPaymentStatus] = useState({status:2});
    const params = useParams();
    useEffect(() => {
        getPaymentStatus();
    }, [])

    //orderPreview
    const getPaymentStatus = async () => {
        const data = {
            'user_id': userId,
            'order_id': params.order_id,
        }
        setIsLoading(true);
        try {
            let res = await axios.post(`${api_url}/get_payment_status`, data)
            if (res.data.success === 1) {
                setPaymentStatus(res.data.data);
            }
            else {
                toast.error('Something Went Wrong', {
                    autoClose: 1500,
                    theme: 'colored'
                });
            }
        } catch (error) {
            toast.error('Axios Error', {
                autoClose: 1500,
                theme: 'colored'
            });
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <>
            <Header />
            <main id='main' className='mt-5 pt-5 space-top'>
                <div className="eVyapari-content w-100">
                    <div className="text-center  mb-2 d-block d-md-none">
                        <Link to={'/'} className="navbar-brand order-md-1 mx-auto" ><img src={logo} alt="e-vyapari-logo" /></Link>
                    </div>
                </div>
                <div className="container">
                    <section className='py-5'>
                        <div className='row'>
                            <div className='col-md-3'></div>
                            <div className='col-md-6 bg-white'>
                                <div className="border b-shadow zoom-2 rounded p-3 pt-0 ">
                                    {
                                        (() => {
                                            switch (paymentStatus?.status) {
                                                case 0:
                                                    return (
                                                        <div className='text-center'>
                                                            <div>
                                                                <img src={require('../assets/images/order_failed.gif')} height={180} alt="" />
                                                                {/* <div className=" fs-4 fw-bold ls-tight text-center">₹{paymentStatus?.transaction_amount}</div> */}
                                                            </div>
                                                            <p className='fs-4 text-center text-danger my-3'>Order Failed (if payment is deducted from your account, then status will be updated within 24 hrs)</p>
                                                        </div>
                                                    );
                                                case 1:
                                                    return (
                                                        paymentStatus?.pay_mode === 'COD' ?
                                                            <div className='text-center'>
                                                                <img src={require('../assets/images/namaste.gif')} className='me-3' height={100} alt="namaste" />
                                                                <div>
                                                                    <img src={require('../assets/images/footer/hindilogo.png')} height={80} alt="logo" />
                                                                </div>
                                                                <div className='h3 color-pink'>Thank you {paymentStatus?.name}</div>
                                                                <div className='h3 text-success mb-3'>Your COD Order Has Been Placed Successfully</div>
                                                            </div>
                                                            :
                                                            <div className='text-center'>
                                                                <img src={require('../assets/images/namaste.gif')} className='me-3' height={100} alt="namaste" />
                                                                <div>
                                                                    <img src={require('../assets/images/footer/logo.png')} height={80} alt="logo" />
                                                                    {/* <div className=" fs-4 fw-bold ls-tight text-center">₹{paymentStatus?.transaction_amount}</div> */}
                                                                </div>
                                                                {/* <img src={require('../assets/images/namaste.gif')} height={100} alt="namaste" /> */}
                                                                <div className='h3 text-danger'>Thank you {paymentStatus?.name}</div>
                                                                <div className='h3 text-success mb-3'>Your Order Has Been Placed Successfully</div>
                                                            </div>
                                                    );
                                                case 2:
                                                    return (
                                                        <div className='text-center'>
                                                            <div>
                                                                {/* <img src={require('../assets/images/order_successfull.gif')} height={180} alt="" /> */}
                                                                {/* <div className=" fs-4 fw-bold ls-tight text-center">₹{paymentStatus?.transaction_amount}</div> */}
                                                            </div>
                                                            <p className='h3 text-warning text-center my-3'>Payment in Process (if payment is deducted from your account, then status will be updated within 24 hrs)</p>
                                                        </div>
                                                    );
                                                default:
                                                    return (
                                                        <div className='text-center pt-4'>
                                                            <p className='h4'>Payment Status</p>
                                                        </div>
                                                    );
                                            }
                                        })()
                                    }
                                    {
                                        paymentStatus?.pay_mode === 'COD' ?
                                            <div className="">
                                                <div className=" fs-4 fw-bold ls-tight text-center">₹{paymentStatus?.transaction_amount}</div>
                                                <div className="r_font1 fw-bold ls-tight text-center">Order Id : {paymentStatus?.order_id}</div>
                                                {/* <div className="r_font1 fw-bold ls-tight text-center">Transaction Id : {paymentStatus?.transaction_id}</div> */}
                                            </div>
                                            :
                                            <div className="">
                                                <div className=" fs-4 fw-bold ls-tight text-center">₹{paymentStatus?.transaction_amount}</div>
                                                <div className="r_font1 fw-bold ls-tight text-center">Order Id : {paymentStatus?.order_id}</div>
                                                <div className="r_font1 fw-bold ls-tight text-center">Transaction Id : {paymentStatus?.transaction_id}</div>
                                            </div>
                                    }

                                    <div className='text-center mb-3'>
                                        <Link to={'/myorders'} className='btn btn-danger'>View My Orders</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </main>
        </>
    )
}

export default PaymentStatus