import React, { useEffect } from 'react'
import WishItem from '../components/WishItem'
import Header from '../layout/Header'
import { useGlobalContext } from '../contexts/GlobalContext'
import logo from '../assets/images/main-logo.svg';
import { Link, useNavigate } from 'react-router-dom'


const MyWishlist = () => {
  const { img_url, convertToPlain, getAllWishlistItems, wishlistData } = useGlobalContext();

  useEffect(() => {
    // getAllWishlistItems();
  }, []);
  const navigate = useNavigate()
  return (
    <>
      {/* {isLoading?<Loading/>:null} */}
      <Header />
      <main id='main' className='mt-5 pt-5 space-top'>
        <div className="eVyapari-content w-100">
          <div className="text-center  mb-2 d-block d-md-none">
            <Link to={'/'} className="navbar-brand order-md-1 mx-auto" ><img src={logo} alt="e-vyapari-logo" /></Link>
          </div>
        </div>
        <section id="cart-top" className="py-md-5 py-2">
          <div className="container">
            <div className="row g-4 d-flex justify-content-between">
              <div className="col-md-4">
                <h5 className="my-cart text-start"> <i className="ti ti-circle-arrow-left cursor-pointer" style={{ cursor: 'pointer' }} onClick={(e) => navigate(-1)} /> <span className='d-inline'> My Wishlist
                </span></h5>
              </div>

            </div>
          </div>
        </section>

        {wishlistData.length !== 0 ?
          <section id="cart-item-list-o" className="py-md-5 py-2 clearfix">
            <div id='wishlist_item'>

              <div className="container">

                <div className="float-md-start list-area text-sm-start text-center mb-2">
                  {wishlistData.map((item, i) => {
                    const { id, product_name, mrp, description, image, folder, alt,discounted_price } = item
                    return (
                      <WishItem
                        key={i}
                        product_id={id}
                        image={`${img_url}/${folder}/${image}`}
                        alt={alt}
                        product_name={product_name}
                        mrp={mrp}
                        discounted_price={discounted_price}
                        description={convertToPlain(description)}
                      />
                    )
                  })}
                </div>
              </div>
            </div>
          </section>
          :
          <div className="container">

            <div>
              <h3 className=' mt-3 text-center wishtext'>Your Wishlist is Empty</h3>
              <div className='row'>
                <div className="col-12 text-center">
                  <img src={require('../assets/images/empty carrt.png')} className='img-fluid d-block mx-auto' alt="" />
                </div>
                <div className='col-12 py-5'>
                  <div className='text-center'>
                    <Link to={'/marketplace'} onClick={() => window.scrollTo({ top: 0, behavior: 'instant' })} className='btn btn-danger py-2 px-3'>Add Items to Wishlist</Link>

                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        {/* {wishlistData.length} */}
      </main>
      {/* <Footer/> */}
    </>
  )
}

export default MyWishlist