import axios from "axios";
import React, { useContext, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { toast } from "react-toastify";
import StateDistrict from "../data/StateDistrict.json";
import * as Icon from "react-bootstrap-icons";
import { useGlobalContext } from "./GlobalContext";

const AppContext = React.createContext();

const InventoryProvider = ({ children }) => {
  const { api_url, userId, sessionType, setCartLength, setIsLoading } =
    useGlobalContext();
  const [searchKeyword, setSearchKeyword] = useState(null);

  //*******************************************All API CALLS********************************************//

  //---------------------------------------Page Inventory -------------------------------------//
  const [pageData, setPageData] = useState({});
  const [pageInventory, setPageInventory] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const inventory_url = `${api_url}/pageInventory`;
  const getPageInventory = async (
    url = inventory_url,
    searchKeyword = null,
    cat_id = null,
    subcat_id = null,
    subcat2_id = null,
    subcat3_id = null
  ) => {
    // console.log("sortby", searchParams.get("sort"));
    const data = {
      user_id: userId,
      searchKeyword: searchParams.get("search"),
      sortKeyword: searchParams.get("sort"),
      cat_id: cat_id,
      subcat_id: subcat_id,
      subcat2_id: subcat2_id,
      subcat3_id: subcat3_id,
    };
    setIsLoading(true);
    try {
      const res = await axios.post(url, data);
      setPageData(res.data.data.pageData);
      setPageInventory(res.data.data.pageData.data);
      // setLastCategories(res.data.data.catData)
      // console.log(res.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const [lastCategories, setLastCategories] = useState([]);
  const getLastCategories = async (
    cat_id = null,
    subcat_id = null,
    subcat2_id = null
  ) => {
    const data = {
      cat_id: cat_id,
      subcat_id: subcat_id,
      subcat2_id: subcat2_id,
    };
    setIsLoading(true);
    try {
      const res = await axios.post(`${api_url}/getLastCategories`, data);
      setLastCategories(res.data.data);
      // console.log(res.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  //--------------------------------------- All Inventory -------------------------------------//
  const [homeInventory, setHomeInventory] = useState([]);
  const getHomeInventory = async () => {
    const data = {
      user_id: userId,
    };
    setIsLoading(true);
    try {
      // const res = await axios.post(`${api_url}/homeInventory`, data);
      // setHomeInventory(res.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  const [homeInventoryNew, setHomeInventoryNew] = useState([]);
  const [homeInventoryBook, setHomeInventoryBook] = useState([]);
  const [homeInventorystationary, setHomeInventorystationary] = useState([]);
  const [homeInventoryBags, setHomeInventoryBags] = useState([]);
  const getHomeInventoryNew = async () => {
    const data = {
      user_id: userId,
    };
    setIsLoading(true);
    try {
      const res = await axios.post(`${api_url}/homeInventorynew`, data);
      setHomeInventoryNew(res.data.data);
      // res.data.data
      setHomeInventoryBook(res.data.data[2]);
      setHomeInventorystationary(res.data.data[1]);
      setHomeInventoryBags(res.data.data[0]);
      // console.log(homeInventoryNew,'home')
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const [allInventory, setAllInventory] = useState([]);
  const getAllInventory = async (
    keyword = null,
    cat_id = null,
    subcat_id = null,
    subcat2_id = null,
    subcat3_id = null
  ) => {
    const data = {
      user_id: userId,
      searchKeyword: keyword,
      cat_id: cat_id,
      subcat_id: subcat_id,
      subcat2_id: subcat2_id,
      subcat3_id: subcat3_id,
    };
    setIsLoading(true);
    try {
      const res = await axios.post(`${api_url}/allInventory`, data);
      setAllInventory(res.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const [vendorInventory, setVendorInventory] = useState([]);
  const [vendorPageData, setVendorPageData] = useState({});
  const getVendorInventory = async (url, vendor_id) => {
    const data = {
      user_id: userId,
      vendor_id: vendor_id,
    };
    setIsLoading(true);
    console.log('clicked',url)
    try {
      const res = await axios.post(url, data);
      setVendorInventory(res.data.data.pageData.data);
      setVendorPageData(res.data.data.pageData);
      // console.log("Vendor Inventory", res.data.data.pageData.data);
    } catch (error) {
      console.log(error);
    } finally { 
      setIsLoading(false);
    }
  };

  //--------------------------------------- Inventory Details -------------------------------------//
  const [inventoryDetails, setInventoryDetails] = useState({});

  const [inventoryImages, setInventoryImages] = useState([]);

  const getInventoryDetails = async (product_id) => {
    setIsLoading(true);
    try {
      const res = await axios.post(`${api_url}/inventoryDetail/${product_id}`);
      setInventoryDetails(res.data.data.inventory);
      setInventoryImages(res.data.data.inv_images);
      // console.log(`${api_url}/inventoryDetail/${product_id}`);
      // console.log("Review Result ", res.data.data.inventory);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  //--------------------------------------- Inventory Details -------------------------------------//
  const [profileDetails, setProfileDetails] = useState({});

  const updateProfileDetails = async (profileimg) => {
    setIsLoading(true);
    const data = {
      user_id: userId,
      profile_image: profileDetails,
    };
    try {
      const res = await axios.post(`${api_url}/updateProfileInformation`, data);
    //   setProfileDetails(res);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  //Get all vendors
  const [vendors, setVendors] = useState([]);
  const getVendors = async () => {
    setIsLoading(true);
    try {
      const res = await axios.post(`${api_url}/getVendors`);
      setVendors(res.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  //--------------------------------------- Add to Cart -------------------------------------//
  const handleAddToCart = async (product_id, qty = 1) => {
    const cartData = {
      user_id: userId,
      product_id: product_id,
      session_type: sessionType,
      qty: qty,
    };

    setIsLoading(true);
    try {
      const res = await axios.post(`${api_url}/addCartProduct`, cartData);
      if (res.data.success === 1) {
        toast.warning(res.data.message, {
          autoClose: 1500,
          theme: "colored",
          style: { color: "#000000" },
          icon: <Icon.Check />,
        });
        setHomeInventory((cartItems) =>
          cartItems.map((CartItem) =>
            CartItem.id === product_id
              ? { ...CartItem, itemExistInCart: true }
              : CartItem
          )
        );
        setHomeInventoryNew((cartItems) => 
          cartItems.map((category) => ({
            ...category, 
            products: category.products.map((product) => {
            
              return product.id === product_id
                ? { ...product, itemExistInCart: true } 
                : product; 
            })
          }))
        );
        setAllInventory((cartItems) =>
          cartItems.map((CartItem) =>
            CartItem.id === product_id
              ? { ...CartItem, itemExistInCart: true }
              : CartItem
          )
        );
        setVendorInventory((cartItems) =>
          cartItems.map((CartItem) =>
            CartItem.id === product_id
              ? { ...CartItem, itemExistInCart: true }
              : CartItem
          )
        );
        setPageInventory((cartItems) =>
          cartItems.map((CartItem) =>
            CartItem.id === product_id
              ? { ...CartItem, itemExistInCart: true }
              : CartItem
          )
        );

        setCartLength((prev) => prev + 1);
      } else {
        toast.error(res.data.message, {
          autoClose: 1500,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AppContext.Provider
      value={{
        searchKeyword,
        homeInventoryBook,homeInventoryBags,homeInventorystationary, setHomeInventoryBook,
        profileDetails, setProfileDetails,
        getHomeInventoryNew,
        homeInventoryNew, setHomeInventoryNew,
        updateProfileDetails,
        setVendorInventory,
        setSearchKeyword,
        setHomeInventory,
        pageInventory,
        getPageInventory,
        pageData,
        setLastCategories,
        allInventory,
        getAllInventory,
        getHomeInventory,
        homeInventory,
        vendorPageData,
        vendorInventory,
        getVendorInventory,
        lastCategories,
        getLastCategories,
        setPageInventory,
        inventoryDetails,
        setInventoryDetails,
        inventoryImages,
        setAllInventory,
        getInventoryDetails,
        handleAddToCart,
        searchParams,
        setSearchParams,
        vendors,
        getVendors,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useInventoryContext = () => {
  return useContext(AppContext);
};
export { InventoryProvider, useInventoryContext };
